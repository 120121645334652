#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: #232741;
}

.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  text-align: center;
  padding: 0 1rem;
}

.home-container h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.home-container p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.home-container div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-container a {
  margin: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid #fff;
  border-radius: 25px;
  background-color: transparent;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.home-container a:hover {
  background: linear-gradient(to right, #BCE6F7, #D9F4C5);
  color: #232741;
  transform: scale(1.05);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(0, 0, 0, 0);
}